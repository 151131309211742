import React, { Component } from 'react';
import aboutImg from '../Assets/Images/about.png';

class About extends Component {
    render() {
        return (
            <div className="hx-about-style-1" id="about">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 col-lg-6 d-xl-flex d-lg-flex d-block align-items-center">
                            <div className="hx-about-content">
                                <div className="hx-site-title">
                                    <span>Builder, Grower, Buyer</span>
                                    <h2>What I'm all about</h2>
                                </div>
                                <p>I have a 12 year track record of building, growing, and selling businesses. From software, to marketing companies, to industrial valve manufacturing, to food trucks. Mostly now working on Cinebody.com, Lumenati.com, and IntentReach.io. Give me a shout if you'd like to work together!</p>                                
                                <div className="btns">
                                    <a href="https://www.cinebody.com/" className="theme-btn">Check Out Cinebody</a>
                                    <a href="https://lumenati.com/" className="theme-btn">Check Out Lumenati</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5">
                            <div className="hx-about-img">
                                <img src={aboutImg}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;